$.fn.countTo = function (a) {
  a = a || {}
  return $(this).each(function () {
    var c = $.extend(
      {},
      $.fn.countTo.defaults,
      {
        from: $(this).data('from'),
        to: $(this).data('to'),
        speed: $(this).data('speed'),
        refreshInterval: $(this).data('refresh-interval'),
        decimals: $(this).data('decimals'),
      },
      a
    )
    var h = Math.ceil(c.speed / c.refreshInterval),
      i = (c.to - c.from) / h
    var j = this,
      f = $(this),
      e = 0,
      g = c.from,
      d = f.data('countTo') || {}
    f.data('countTo', d)
    if (d.interval) {
      clearInterval(d.interval)
    }
    d.interval = setInterval(k, c.refreshInterval)
    b(g)
    function k() {
      g += i
      e++
      b(g)
      if (typeof c.onUpdate == 'function') {
        c.onUpdate.call(j, g)
      }
      if (e >= h) {
        f.removeData('countTo')
        clearInterval(d.interval)
        g = c.to
        if (typeof c.onComplete == 'function') {
          c.onComplete.call(j, g)
        }
      }
    }
    function b(m) {
      var l = c.formatter.call(j, m, c)
      f.html(l)
    }
  })
}

$.fn.countTo.defaults = {
  from: 0,
  to: 0,
  speed: 1000,
  refreshInterval: 100,
  decimals: 0,
  onUpdate: null,
  onComplete: null,
  formatter: function (b, a) {
    return b.toFixed(0)
  },
}

$(function () {
  var running = true
  setTimeout(function () {
    $('.header-panel').show()
  }, 500)
  $('.header-menu-btn').bind('click', function () {
    const $this = $(this)
    $this.toggleClass('active')
    $('.header-panel').toggleClass('active')
  })

  if ($('.about-data').length > 0) {
    var aboutData = $('.about-data').offset().top
    var windowHeight = $(window).height()

    $(window).scroll(function () {
      var scrollTop = $(window).scrollTop()
      if (scrollTop + windowHeight > aboutData && running) {
        running = false
        $('.timer').each(function (a) {
          var b = $(this)
          a = $.extend({}, a || {}, b.data('countToOptions') || {})
          b.countTo(a)
        })
      }
    })
  }

  if ($('#homeServe').length > 0) {
    var aboutData = $('#homeServe').offset().top
    var windowHeight = $(window).height()

    $(window).scroll(function () {
      var scrollTop = $(window).scrollTop()
      if (scrollTop + windowHeight > aboutData && running) {
        running = false
        $('.timer').each(function (a) {
          var b = $(this)
          a = $.extend({}, a || {}, b.data('countToOptions') || {})
          b.countTo(a)
        })
      }
    })
  }

  if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
    setTimeout(function () {
      var activeLi = $('.header-menu_item.active')
      var activeDropdown = activeLi.find('.header-dropdown-menu li')
      var activeLength = activeDropdown.length
      if (activeLength > 0) {
        var activeHeight = activeDropdown.height() * activeLength
        activeLi.find('.header-dropdown').height(activeHeight)
      }
    }, 1000)

    $('#menus').on('click', '.header-menu_item > a', function (event) {
      event.preventDefault()
      var href = $(this).attr('href')
      var parent = $(this).parent()
      var dropdown = parent.find('.header-dropdown-menu li')
      var length = dropdown.length
      if (length < 1) return (window.location.href = href)
      if (parent.hasClass('active')) {
        parent.find('.header-dropdown').height(0)
        parent.removeClass('active')
      } else {
        var height = dropdown.outerHeight() * length
        parent.find('.header-dropdown').height(height)
        parent.addClass('active')
      }
    })

    $('#menus').on('click', '.header-dropdown-menu li a', function (event) {
      event.preventDefault()
      var href = $(this).attr('href')
      var parent = $(this).parent()
      var grands = $(this).parents('.header-dropdown')
      var dropdown = parent.find('.header-submenu a')
      var grandChild = grands.find('.header-dropdown-menu > li')
      var grandChildCount = grandChild.length
      var length = dropdown.length
      var height = dropdown.outerHeight()
      if (length < 1) return (window.location.href = href)
      if (parent.hasClass('active')) {
        parent.find('.header-submenu').height(0)
        grands.height(height * grandChildCount)
        parent.removeClass('active')
      } else {
        parent.find('.header-submenu').height(height * length)
        grands.height(height * grandChildCount + height * length)
        parent.addClass('active')
      }
    })
  }
})
